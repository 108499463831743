<template>
  <router-link :to="`/consolidated/${data.id}`" :style="{display: 'block'}">
    <div :class="[{ 'default-billing-account': data.is_default }, 'card']">
      <div class="card-content">
        <div class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <strong>
                {{data.name?data.name:'Anonymous'}}
              </strong> <default-button
                class="ml-4 has-tooltip-right"
                data-tooltip="By making this your default account, any paid usages in Pozetron services will be registered to it."
                type="consolidated"
                :id="data.id"
                :is_default="data.is_default"
                @made_default="$emit('made_default')"
              />
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">

            <div class="level-item">
              <div class="tags has-addons">
                <span class="tag is-info">Balance</span>
                <span class="tag"><strong>{{centimes_to_credits(data.balance)}} Credits</strong></span>
              </div>
            </div>

            <div class="level-item">
              <div class="buttons">
                <button
                  v-if="!data.balance"
                  :class="['delete-button button is-white', {'is-loading': deleting}]"
                  @click.prevent="deleteAccount()"
                >
                  <span :class="['icon is-small', {'has-text-danger': !deleting}]">
                    <font-awesome-icon :icon="['fas', 'times']" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import api_billing from './../../../apis/user_api/billing';
import { get_sso_url } from './../../../utils/sso.js';
import { centimes_to_credits } from './../../../utils/credits'

import DefaultButton from './../DefaultButton'


export default {
  name: 'consolidated-billing-account-list-item',
  components: {
    DefaultButton
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  emits: ['made_default', 'deleted'],
  data() {
    return {
      isDropdownActive: false,
      deleting: false,
      deleting_failed: false,
    }
  },
  methods: {
    centimes_to_credits,
    deleteAccount() {
      return this.$confirm(
        'Are you sure?',
        null,
        'warning',
        {
          confirmButtonText: 'Yes, delete now'
        }
      )
      .then(
        () => {
          this.deleting = true
          this.deleting_failed = false
          return api_billing.delete_consolidated_account(this.data.id)
            .then(response => {
              if (response.status == 204) {
                this.deleting = false
                this.$emit('deleted')
              } else {
                return Promise.reject({response})
              }
            })
            .catch(error => {
              this.deleting = false
              this.deleting_failed = true
              if (error.response && error.response.status == 401) {
                // Redirect to single sign-on url
                window.location.assign(get_sso_url())
              } else {
                alert('Something went wrong.')
                // re-raise
                return Promise.reject(error)
              }
            })
        },
        () => {
          // do nothing on cancel
          return Promise.resolve()
        }
      )
    }
  }
}
</script>

<style scoped>
.is-disabled{
  pointer-events: none;
  opacity: .65;
}
.default-billing-account {
  border: 2px solid #fc6e51;
}
</style>
