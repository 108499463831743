import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import config from './../../app.config.json'


export const get_jwt = () => {
  const jwt_cookie = Cookies.get(config.SSO_JWT_COOKIE_NAME)
  return jwt_cookie !== undefined
    ?jwt_cookie
    :config.DEV_FALLBACK_JWT
}


export const get_jwt_payload = () => {
  try {
    return jwt_decode(get_jwt())
  } catch (error) {
    return null
  }
}


export const is_phone_number_verified = () => {
  return get_jwt_payload() !== null && get_jwt_payload().verified === 'phone'
}
