<template>
  <div>
    <div :class="[{ 'default-billing-account': data.is_default }, 'card']">
      <div class="card-content">
        <div class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <strong>
                {{data.account_name}}
                {{data.account_name?data.account_name:'Anonymous'}}
              </strong>
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">

            <div class="level-item">
              <div class="buttons">
                <button
                  :class="['delete-button button is-white', {'is-loading': patching}]"
                  @click.prevent="acceptMembership(false)"
                >
                  Accept
                </button>
                <button
                  :class="['delete-button button is-white', {'is-loading': patching}]"
                  @click.prevent="acceptMembership(true)"
                >
                  Accept and set as default
                </button>
                <button
                  :class="['delete-button button is-white', {'is-loading': patching}]"
                  @click.prevent="deleteMembership()"
                >
                  Ignore
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api_billing from './../../../apis/user_api/billing';
import { get_sso_url } from './../../../utils/sso.js';
import { centimes_to_credits } from './../../../utils/credits'


export default {
  name: 'pending-membership-account-list-item',
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  emits: ['accepted', 'deleted'],
  data() {
    return {
      isDropdownActive: false,
      patching: false,
      patching_failed: false,
    }
  },
  methods: {
    centimes_to_credits,
    acceptMembership(make_default = false) {
      return (
        make_default
          ?this.$confirm(
            'Are you sure you also want to set this as your default billing account?',
            null,
            'warning',
            {
              confirmButtonText: 'Yes'
            }
          )
          :Promise.resolve()
      )
      .then(
        () => {
          this.patching = true
          this.patching_failed = false
          return api_billing.edit_membership_as_member(this.data.id, {is_accepted: true, ...make_default?{is_default: true}:{}})
            .then(response => {
              if (response.status == 200) {
                this.patching = false
                this.$emit('accepted')
              } else {
                return Promise.reject({response})
              }
            })
            .catch(error => {
              this.patching = false
              this.patching_failed = true
              if (error.response && error.response.status == 401) {
                // Redirect to single sign-on url
                window.location.assign(get_sso_url())
              } else {
                alert('Something went wrong.')
                // re-raise
                return Promise.reject(error)
              }
            })
        },
        () => {
          // do nothing on cancel
          return Promise.resolve()
        }
      )
    },
    deleteMembership() {
      this.patching = true
      this.patching_failed = false
      return api_billing.delete_membership_as_member(this.data.id)
        .then(response => {
          if (response.status == 204) {
            this.patching = false
            this.$emit('deleted')
          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          this.patching = false
          this.patching_failed = true
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    }
  }
}
</script>

<style scoped>
.is-disabled{
  pointer-events: none;
  opacity: .65;
}
.default-billing-account {
  border: 2px solid #fc6e51;
}
</style>
