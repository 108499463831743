<template>

  <div>

    <div class="field is-horizontal">
      <div class="field-label has-text-left">
        <label class="label">Name</label>
      </div>
      <div class="field-body">
        <div class="field">
          {{ account.name }}
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-label has-text-left">
        <label class="label">Address line 1</label>
      </div>
      <div class="field-body">
        <div class="field">
          {{ account.address_line_1 }}
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label has-text-left">
        <label class="label">Address line 2</label>
      </div>
      <div class="field-body">
        <div class="field">
          {{ account.address_line_2 }}
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-label has-text-left">
        <label class="label">City</label>
      </div>
      <div class="field-body">
        <div class="field">
          {{ account.city }}
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label has-text-left">
        <label class="label">State / Province</label>
      </div>
      <div class="field-body">
        <div class="field">
          {{ account.state }}
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label has-text-left">
        <label class="label">Postal Code</label>
      </div>
      <div class="field-body">
        <div class="field">
          {{ account.postal_code }}
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-label has-text-left">
        <label class="label">Country</label>
      </div>
      <div class="field-body">
        <div class="field">
          {{ account.country }}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'account-details',
  props: {
    account: {
      type: Object,
      required: true
    }
  }
};
</script>
