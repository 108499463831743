<template>
  <div>


    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Name</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              v-model="v$.form.name.$model"
              :class="['input', {'is-danger': v$.form.name.$error}]"
              type="text"
              placeholder="Full name"
            />
            <div v-if="v$.form.name.$error">
              <p class="help is-danger" v-if="!v$.form.name.required">Field is required</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Address line 1</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              v-model="v$.form.address_line_1.$model"
              :class="['input', {'is-danger': v$.form.address_line_1.$error}]"
              type="text"
              placeholder="Address line 1"
            />
            <div v-if="v$.form.address_line_1.$error">
              <p class="help is-danger" v-if="!v$.form.address_line_1.required">Field is required</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Address line 2</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              v-model="v$.form.address_line_2.$model"
              :class="['input', {'is-danger': v$.form.address_line_2.$error}]"
              type="text"
              placeholder="Address line 2"
            />
            <div v-if="v$.form.address_line_2.$error">
              <p class="help is-danger" v-if="!v$.form.address_line_2.required">Field is required</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">City</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              v-model="v$.form.city.$model"
              :class="['input', {'is-danger': v$.form.city.$error}]"
              type="text"
              placeholder="City"
            />
            <div v-if="v$.form.city.$error">
              <p class="help is-danger" v-if="!v$.form.city.required">Field is required</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">State / Province</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              v-model="v$.form.state.$model"
              :class="['input', {'is-danger': v$.form.state.$error}]"
              type="text"
              placeholder="State / Province"
            />
            <div v-if="v$.form.state.$error">
              <p class="help is-danger" v-if="!v$.form.state.required">Field is required</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Postal code</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              v-model="v$.form.postal_code.$model"
              :class="['input', {'is-danger': v$.form.postal_code.$error}]"
              type="text"
              placeholder="Postal code"
            />
            <div v-if="v$.form.postal_code.$error">
              <p class="help is-danger" v-if="!v$.form.postal_code.required">Field is required</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label has-text-left">Country</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              v-model="v$.form.country.$model"
              :class="['input', {'is-danger': v$.form.country.$error}]"
              type="text"
              placeholder="Country"
            />
            <div v-if="v$.form.country.$error">
              <p class="help is-danger" v-if="!v$.form.country.required">Field is required</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <button
            :class="['forward-button button is-pozetron-primary has-tooltip-left', {'is-loading': submitting}]"
            :disabled="v$.$invalid"
            :data-tooltip="v$.$invalid?'Please ensure all fields are valid':null"
            @click.prevent="handleSubmit"
          >
            Save
          </button>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <div class="level-item">
          <button
            class="back-button button is-light"
            :disabled="submitting"
            @click.prevent="$emit('close')"
          >Cancel</button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import api_billing from './../../../apis/user_api/billing.js'
import { get_sso_url } from './../../../utils/sso.js'

export default {
  name: 'edit-personal-account-form',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  emits: ['close', 'submitted'],
  props: {
    initial: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['personal', 'consolidated'].indexOf(value) !== -1
      }
    }
  },
  data() {
    return {
      loading: false,
      loading_failed: false,
      submitting: false,
      form: {
        name: this.initial.name,
        address_line_1: this.initial.address_line_1,
        address_line_2: this.initial.address_line_2,
        city: this.initial.city,
        state: this.initial.state,
        postal_code: this.initial.postal_code,
        country: this.initial.country,
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      address_line_1: {
        required
      },
      address_line_2: {

      },
      city: {
        required
      },
      state: {
        required
      },
      postal_code: {
        required
      },
      country: {
        required
      },
    }
  },
  methods: {
    handleSubmit() {
      this.submitting = true

      return (
        this.type == 'personal'
          ?api_billing.edit_personal_account(this.form)
          :api_billing.edit_consolidated_account(this.initial.id, this.form)
      )
        .then(response => {
          if (response.status == 200) {
            this.submitting = false
            this.$emit('submitted', response.data)
            return Promise.resolve()
          } else {
            return Promise.reject(response)
          }
        })
        .catch(error => {
          this.submitting = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else if (error.response && error.response.status == 422) {
            alert('Data failed server-side validation.')
            // re-raise, so developers can be alerted of caught edge-case
            return Promise.reject(error)
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
  }
}
</script>
