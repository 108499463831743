<template>

  <button
    v-if="!is_default"
    :class="['button is-small', {'is-loading': patching}]"
    @click.prevent="setAsDefault()"
  >Set as default</button>

  <button
    v-else
    class="button is-small is-pozetron-primary"
    :style="{cursor: 'initial'}"
    @click.prevent=""
  >
    <span class="icon is-small">
      <font-awesome-icon :icon="['fas', 'check']" />
    </span>
    <span>Default</span>
  </button>
</template>

<script>
import api_billing from './../../apis/user_api/billing';
import { get_sso_url } from './../../utils/sso';


export default {
  name: 'default-button',
  props: {
    id: {
      type: String,
      required: true
    },
    is_default: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['personal', 'consolidated', 'membership'].indexOf(value) !== -1
      }
    }
  },
  emits: ['made_default'],
  data() {
    return {
      patching: false,
      patching_failed: false,
    }
  },
  methods: {
    setAsDefault() {
      return this.$confirm(
        'Are you sure?',
        null,
        'warning',
        {
          confirmButtonText: 'Yes'
        }
      )
      .then(
        () => {
          this.patching = true
          this.patching_failed = false

          let api_promise

          switch (this.type) {
            case 'personal':
              api_promise = api_billing.edit_personal_account({is_default: true})
              break;
            case 'consolidated':
              api_promise = api_billing.edit_consolidated_account(this.id, {is_default: true})
              break;
            case 'membership':
              api_promise = api_billing.edit_membership_as_member(this.id, {is_default: true})
              break;
          }

          return api_promise.then(response => {
            if (response.status == 200) {
              this.patching = false
              this.$emit('made_default')
            } else {
              return Promise.reject({response})
            }
          })
          .catch(error => {
            this.patching = false
            this.patching_failed = true
            if (error.response && error.response.status == 401) {
              // Redirect to single sign-on url
              window.location.assign(get_sso_url())
            } else {
              alert('Something went wrong.')
              // re-raise
              return Promise.reject(error)
            }
          })
        },
        () => {
          // do nothing on cancel
          return Promise.resolve()
        }
      )
    }
  },
};
</script>
