<template>
  <div>

    <div class="container is-fluid">

      <loading-indicator v-if="loading" text="Loading.." />

      <section v-else-if="!loading && loading_failed" class="section has-text-centered">
        <span class="icon is-large">
          <font-awesome-icon class="fa-3x" :icon="['fas', 'exclamation-triangle']" />
        </span>
        <br/>
        <br/>
        An error happened while fetching data.
        <br/>
        <br/>
        <button
          class="retry-button button is-pozetron-primary"
          @click.prevent="loadData()"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'sync']" />
          </span>
          <span>Retry</span>
        </button>
      </section>

      <div v-else-if="!loading && !loading_failed">

        <div>
          <div class="header level">
            <!-- Left side -->
            <div class="level-left">
              <div class="level-item">
                <h3 class="subtitle">Personal Billing</h3>
              </div>
            </div>

          </div>

          <personal-billing-account-list-item
            :data="personal"
            @made_default="loadData()"
          />

        </div>

        <hr :style="{backgroundColor: '#ddd'}" />

        <div>

          <div class="header level">
            <!-- Left side -->
            <div class="level-left">
              <div class="level-item">
                <h3 class="subtitle">Consolidated Billing</h3>
                <strong
                  class="tag is-small is-pozetron-primary ml-2 has-tooltip-right"
                  data-tooltip="Use Consolidated Billing to manage the billing and payments for multiple Pozetron users with a single account."
                >
                  ?
                </strong>
              </div>
            </div>


            <!-- Right side -->
            <div class="level-right">
              <div class="level-item">
                <button
                  class="add-new-button button is-pozetron-primary"
                  @click.prevent="is_create_consolidated_account_modal_active = true"
                >
                  <span>Add new</span>
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div v-if="!consolidated.length">You did not create any consolidated billing accounts.</div>
          <div v-else>
            <consolidated-billing-account-list-item
              v-for="account in consolidated"
              class="mb-4"
              :key="account.id"
              :data="account"
              @deleted="loadData()"
              @made_default="loadData()"
            />
          </div>

        </div>

        <hr :style="{backgroundColor: '#ddd'}" />

        <div>

          <div class="header level">
            <!-- Left side -->
            <div class="level-left">
              <div class="level-item">
                <h3 class="subtitle">Consolidated Billing accounts I am invited in</h3>
                <strong
                  class="tag is-small is-pozetron-primary ml-2 has-tooltip-right"
                  data-tooltip="Here, you will find any invitations you have received from other users who want you to use their Consolidated Billing accounts."
                >
                  ?
                </strong>
              </div>
            </div>

          </div>

          <div v-if="memberships.length">

            <div class="tabs">
              <ul>
                <li :class="{'is-active': active_tab === 'active_memberships'}">
                  <a href="#" @click.prevent="active_tab='active_memberships'" :style="{paddingTop: '0.75em', paddingBottom: '0.75em'}">
                    Active <span class="tag is-light ml-2">{{memberships.filter(i => i.is_accepted).length}}</span>
                  </a>
                </li>
                <li :class="{'is-active': active_tab === 'pending_memberships'}">
                  <a href="#" @click.prevent="active_tab='pending_memberships'" :style="{paddingTop: '0.75em', paddingBottom: '0.75em'}">
                    Pending acceptance <span class="tag is-light ml-2">{{memberships.filter(i => !i.is_accepted).length}}</span>
                  </a>
                </li>
              </ul>
            </div>

            <div v-if="active_tab === 'active_memberships'">
              <active-membership-account-list-item
                v-for="account in memberships.filter(i => i.is_accepted)"
                class="mb-4"
                :key="account.id"
                :data="account"
                @deleted="loadData()"
                @made_default="loadData()"
              />
            </div>

            <div v-if="active_tab === 'pending_memberships'">
              <pending-membership-account-list-item
                v-for="account in memberships.filter(i => !i.is_accepted)"
                class="mb-4"
                :key="account.id"
                :data="account"
                @deleted="loadData()"
                @accepted="loadData()"
              />
            </div>
          </div>
          <div v-else>You did not receive any invitations to Consolidated Billing accounts.</div>

        </div>

        <bulma-modal
          v-if="is_create_consolidated_account_modal_active"
          :is_active="true"
          @close="is_create_consolidated_account_modal_active = false"
        >
          <create-consolidated-account-form
            :owner="me.keyId"
            @close="is_create_consolidated_account_modal_active = false"
            @submitted="is_create_consolidated_account_modal_active = false; $router.push({ path: `/consolidated/${$event.id}` })"
          />
        </bulma-modal>

      </div>

    </div>

  </div>
</template>

<script>
import { checkAuthBeforeRouteEnter } from './../utils/router'
import api_billing from './../apis/user_api/billing.js'
import LoadingIndicator from './../components/common/LoadingIndicator.vue'
import BulmaModal from './../components/common/BulmaModal.vue'
import CreateConsolidatedAccountForm from './_parts/forms/CreateConsolidatedAccountForm'
import PersonalBillingAccountListItem from './_parts/list_items/PersonalBillingAccountListItem'
import ConsolidatedBillingAccountListItem from './_parts/list_items/ConsolidatedBillingAccountListItem'
import ActiveMembershipAccountListItem from './_parts/list_items/ActiveMembershipAccountListItem'
import PendingMembershipAccountListItem from './_parts/list_items/PendingMembershipAccountListItem'

export default {
  name: 'home-screen',
  components: {
    LoadingIndicator,
    BulmaModal,
    CreateConsolidatedAccountForm,
    PersonalBillingAccountListItem,
    ConsolidatedBillingAccountListItem,
    ActiveMembershipAccountListItem,
    PendingMembershipAccountListItem
  },
  data() {
    return {
      loading: true,
      loading_failed: false,
      me: null,  // to be loaded by beforeRouteEnter()
      personal: null,
      consolidated: [],
      memberships: [],
      is_create_consolidated_account_modal_active: false,
      active_tab: 'active_memberships',
    }
  },
  methods: {
    loadPersonal() {
      this.personal = null
      return api_billing.get_personal_account()
        .then(response => {
          if (response.status == 200) {
            this.personal = response.data
            return Promise.resolve(response.data)
          } else {
            return Promise.reject({response})
          }
        })
    },
    loadConsolidated() {
      this.consolidated = []
      return api_billing.get_consolidated_accounts_list()
        .then(response => {
          if (response.status == 200) {
            this.consolidated = response.data
            return Promise.resolve(response.data)
          } else {
            return Promise.reject({response})
          }
        })
    },
    loadMemberships() {
      this.memberships = []
      return api_billing.get_memberships_list_as_member()
        .then(response => {
          if (response.status == 200) {
            this.memberships = response.data
            return Promise.resolve(response.data)
          } else {
            return Promise.reject({response})
          }
        })
    },
    loadData() {
      this.loading = true
      this.loading_failed = false
      return this.loadPersonal()
        .then(() => this.loadConsolidated())
        .then(() => this.loadMemberships())
        .then(() => {
          this.loading = false
          return Promise.resolve()
        })
        .catch((e) => {
          this.loading = false
          this.loading_failed = true
          return Promise.reject(e)
        })
    }
  },
  mounted: function() {
    this.loadData()
  },
  async beforeRouteEnter (to, from, next) {
    await checkAuthBeforeRouteEnter(to, from, next)
  },
}
</script>

<style>
.header {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.header .title{
  font-weight: normal;
}
</style>
