import api_base from './base.js'


const api_billing = {
  get_default_account: () => (
    api_base(true).get('user/v1/billing/default/')
  ),
  get_personal_account: () => (
    api_base(true).get('user/v1/billing/personal/me/')
  ),
  edit_personal_account: (data) => (
    api_base(true).patch('user/v1/billing/personal/me/', data)
  ),
  edit_consolidated_account: (id, data) => (
    api_base(true).patch(`user/v1/billing/consolidated/${id}/`, data)
  ),
  create_consolidated_account: (data) => (
    api_base(true).post('user/v1/billing/consolidated/', data)
  ),
  get_consolidated_account: (id) => (
    api_base(true).get(`user/v1/billing/consolidated/${id}/`)
  ),
  get_consolidated_accounts_list: () => (
    api_base(true).get('user/v1/billing/consolidated/?owner_id=me')
  ),
  delete_consolidated_account: (id) => (
    api_base(true).delete(`user/v1/billing/consolidated/${id}/`)
  ),
  get_memberships_list_as_owner: (account_id) => (
    api_base(true).get(`user/v1/billing/memberships/as-account-owner/?account_id=${account_id}`)
  ),
  create_membership_as_owner: (data) => (
    api_base(true).post('user/v1/billing/memberships/as-account-owner/', data)
  ),
  delete_membership_as_owner: (id) => (
    api_base(true).delete(`user/v1/billing/memberships/as-account-owner/${id}/`)
  ),
  get_memberships_list_as_member: () => (
    api_base(true).get('user/v1/billing/memberships/as-member/?owner_id=me')
  ),
  delete_membership_as_member: (id) => (
    api_base(true).delete(`user/v1/billing/memberships/as-member/${id}/`)
  ),
  edit_membership_as_member: (id, data) => (
    api_base(true).patch(`user/v1/billing/memberships/as-member/${id}/`, data)
  ),
}

export default api_billing
