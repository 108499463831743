<template>
  <section class="section has-text-centered">
    <span class="icon is-large">
      <font-awesome-icon class="fa-3x fa-pulse" :icon="['fas', 'spinner']" />
    </span>
    <br/>
    <br/>
    {{text}}
  </section>
</template>

<script>
export default {
  name: 'loading-indicator',
  props: {
    text: {
      type: String,
      default: 'Loading..'
    }
  }
}
</script>
