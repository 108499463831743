import PozetronWebSharedLayout from './components/layout/pozetron-web-shared-layout.vue'

import config from '../app.config.json'

import HomeScreen from './screens/HomeScreen.vue'
import PersonalAccountScreen from './screens/PersonalAccountScreen.vue'
import ConsolidatedAccountScreen from './screens/ConsolidatedAccountScreen.vue'


const routes = [
  {
    path: '/',
    component: PozetronWebSharedLayout,
    props: {
      devices_app_base_url: config.DEVICES_APP_BASE_URL,
      actions_app_base_url: config.ACTIONS_APP_BASE_URL,
      dashboards_base_url: config.DASHBOARDS_BASE_URL,
      billing_app_base_url: '',
      shop_base_url: config.SHOP_BASE_URL,
      tutorials_base_url: config.TUTORIALS_BASE_URL
    },
    children: [
      {
        path: '',
        component: HomeScreen,
        name: 'home',
      },
      {
        path: 'personal',
        component: PersonalAccountScreen,
        name: 'personal',
      },
      {
        path: 'consolidated/:id',
        component: ConsolidatedAccountScreen,
        name: 'consolidated',
        props: true,
      }
    ]
  }
]


export default routes
