<template>
  <div>
    <div class="container is-fluid">
      <loading-indicator v-if="loading" text="Loading.." />

      <section
        v-else-if="!loading && loading_failed"
        class="section has-text-centered"
      >
        <span class="icon is-large">
          <font-awesome-icon
            class="fa-3x"
            :icon="['fas', 'exclamation-triangle']"
          />
        </span>
        <br />
        <br />
        An error happened while fetching data.
        <br />
        <br />
        <button
          class="retry-button button is-pozetron-primary"
          @click.prevent="loadData()"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'sync']" />
          </span>
          <span>Retry</span>
        </button>
      </section>

      <div v-else-if="!loading && !loading_failed">
        <div>
          <div class="mt-6 mb-6">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <div class="level">
                    <!-- Left side -->
                    <div class="level-left">
                      <div class="level-item">
                        <p>
                          <strong>Personal</strong> Billing Account
                          <br />
                          <span class="is-size-7"
                            >This is your main billing account. It is permanently
                            associated with your Pozetron account and cannot be
                            removed.</span
                          >
                        </p>
                      </div>
                    </div>
                    <!-- Right side -->
                    <div class="level-right">
                      <div class="level-item">
                        <default-button
                          class="ml-4 has-tooltip-left"
                          data-tooltip="By making this your default account, any paid usages in Pozetron services will be registered to it."
                          type="personal"
                          :id="data.id"
                          :is_default="data.is_default"
                          @made_default="loadData()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div class="card mb-5">
            <header class="card-header">
              <p class="card-header-title">Balance</p>
            </header>
            <div class="card-content">
              <div class="level">
                <!-- Left side -->
                <div class="level-left">
                  <div class="level-item">
                    <div class="content">
                      Your balance is
                      <strong
                        >{{ centimes_to_credits(data.balance) }} Credits</strong
                      >.
                      <br />
                      <span class="is-size-7"
                        >Top-up your account with credits so you can use them in Pozetron services.</span
                      >
                    </div>
                  </div>
                </div>

                <!-- Right side -->
                <div class="level-right">
                  <div class="level-item">
                    <!-- <button
                      class="button is-pozetron-primary snipcart-add-item"
                      data-item-id="POZ_CREDITS"
                      data-item-price="1"
                      :data-item-url="`${USER_API_URL}/user/v1/billing/snipcart/items/?format=json`"
                      data-item-name="Pozetron Credits"
                      :data-item-description="`For personal billing account: ${data.id}`"
                      data-item-quantity="10"
                      data-item-quantity-step="5"
                      data-item-stackable="never"
                      data-item-shippable="false"
                      :data-item-metadata="
                        JSON.stringify({ billing_account_id: data.id })
                      "
                    > -->
                    <button
                    class="button is-pozetron-primary"
                    @click="is_credit_modal_active = true">
                      Top-up now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <bulma-modal
            v-if="is_credit_modal_active"
            :is_active="true"
            @close="is_credit_modal_active = false"
          >
          <div>


            <header class="modal-card-head">
              <p class="modal-card-title">Beta Credits</p>
              <button
                class="button is-white"
                @click="is_credit_modal_active = false"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'times']" />
                </span>
              </button>
            </header>
            <section class="modal-card-body">

              <div class="message is-info">
                <div class="message-body">
                  During the Beta period if you would like credits email <a href="mailto:credits@pozetroninc.com">credits@pozetroninc.com</a> to find out how.
                </div>
              </div>
            </section>
          </div>
          </bulma-modal>

          <div class="card mb-5">
            <header class="card-header">
              <p class="card-header-title">Account details <strong
                  class="tag is-small is-light ml-2 has-tooltip-right"
                  data-tooltip="This information will appear on invoices we send you and should contain the legal name and address associated with your home or business."
                >
                  ?
                </strong>
              </p>
              <a
                v-if="!editing_details"
                href="#"
                class="card-header-icon"
                @click.prevent="editing_details = true"
              >
                Edit
              </a>
              <a
                v-else
                href="#"
                class="card-header-icon"
                @click.prevent="editing_details = false"
              >
                Cancel
              </a>
            </header>
            <div class="card-content">

              <account-details
                v-if="!editing_details"
                :account="data"
              />

              <edit-account-details-form
                v-else
                :initial="data"
                type="personal"
                @close="editing_details = false"
                @submitted="
                  editing_details = false;
                  loadData();
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkAuthBeforeRouteEnter } from './../utils/router'
import api_billing from './../apis/user_api/billing.js';
import LoadingIndicator from './../components/common/LoadingIndicator.vue';
import { centimes_to_credits } from './../utils/credits';
import config from './../../app.config.json'

import EditAccountDetailsForm from './_parts/forms/EditAccountDetailsForm';
import AccountDetails from './_parts/AccountDetails';
import DefaultButton from './_parts/DefaultButton';
import BulmaModal from './../components/common/BulmaModal.vue'


export default {
  name: 'single-account-screen',
  components: {
    LoadingIndicator,
    EditAccountDetailsForm,
    AccountDetails,
    DefaultButton,
    BulmaModal
  },
  data() {
    return {
      loading: true,
      loading_failed: false,
      me: null,  // to be loaded by beforeRouteEnter()
      data: null,
      editing_details: false,
      is_credit_modal_active: false,
    };
  },
  computed: {
    USER_API_URL: function () {
      return config.USER_API_URL
    }
  },
  methods: {
    centimes_to_credits,
    loadData() {
      this.loading = true;
      this.loading_failed = false;
      return api_billing
        .get_personal_account()
        .then((response) => {
          if (response.status == 200) {
            this.data = response.data;
            this.loading = false;
            return Promise.resolve(response.data);
          } else {
            return Promise.reject({ response });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.loading_failed = true;
          return Promise.reject(error);
        });
    },
    onSnipcartItemAdding() {
      // Remove any existing items before adding any new item
      // So we can ensure there is ever only one item in the cart
      window.Snipcart.store.getState().cart.items.items.forEach((item) => {
        window.Snipcart.api.cart.items.remove(item.uniqueId);
      });

      // Pre-fill cart billing info
      window.Snipcart.api.cart.update({
        email: this.me.email,
        billingAddress: {
          name: this.data.name,
          address1: this.data.address_line_1,
          address2: this.data.address_line_2,
          city: this.data.city,
          province: this.data.state,
          country: this.data.country,
          company: '',
          postalCode: this.data.postal_code,
        },
      });
    },
    onSnipcartRouteChanged(routesChange) {
      if (routesChange.from === '/' && routesChange.to !== '/') {
        // cart opened

        // removing any existing cart items
        window.Snipcart.store.getState().cart.items.items.forEach((item) => {
          window.Snipcart.api.cart.items.remove(item.uniqueId);
        });
      }

      if (routesChange.from !== '/' && routesChange.to === '/') {
        // cart closed

        // removing any existing cart items
        window.Snipcart.store.getState().cart.items.items.forEach((item) => {
          window.Snipcart.api.cart.items.remove(item.uniqueId);
        });

      }

      if (routesChange.from.indexOf('order/') >= 0 && routesChange.to === '/') {
        // cart closed from inside completed order page

        // Refresh page
        this.loadData()
      }
    },
    onSnipcartReady() {
      this.itemAddingUnsubscribe = window.Snipcart.events.on('item.adding', this.onSnipcartItemAdding);
      this.routeChangedUnsubscribe = window.Snipcart.events.on('theme.routechanged', this.onSnipcartRouteChanged);
    },
  },
  mounted: function () {
    this.loadData();
    document.addEventListener('snipcart.ready', this.onSnipcartReady)
  },
  unmounted() {
    document.removeEventListener('snipcart.ready', this.onSnipcartReady);

    if (this.itemAddingUnsubscribe) {
      this.itemAddingUnsubscribe()
    }

    if (this.routeChangedUnsubscribe) {
      this.routeChangedUnsubscribe()
    }
  },
  async beforeRouteEnter (to, from, next) {
    await checkAuthBeforeRouteEnter(to, from, next)
  },
};
</script>

<style>
.header {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.header .title {
  font-weight: normal;
}
</style>
