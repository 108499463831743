import api_base from './base.js'


const api_user = {
  get_me: () => (
    api_base(true).get('user/v1/me/')
  ),
}

export default api_user
