<template>
  <div class="card mb-5">
    <header class="card-header">
      <p class="card-header-title">Account Members <strong
          class="tag is-small is-light ml-2 has-tooltip-right"
          data-tooltip="Other Pozetron users, invited here, will be able to select this account for covering their usages in paid Pozetron services."
        >
          ?
        </strong>
      </p>
      <a
        href="#"
        class="card-header-icon"
        @click.prevent="is_invite_members_modal_active = true"
      >
        Add a member
      </a>
    </header>
    <div class="card-content" :style="{padding: 0}">

      <loading-indicator v-if="loading" text="Loading.." />

      <section
        v-else-if="!loading && loading_failed"
        class="section has-text-centered"
      >
        <span class="icon is-large">
          <font-awesome-icon
            class="fa-3x"
            :icon="['fas', 'exclamation-triangle']"
          />
        </span>
        <br />
        <br />
        An error happened while fetching data.
        <br />
        <br />
        <button
          class="retry-button button is-pozetron-primary"
          @click.prevent="loadData()"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'sync']" />
          </span>
          <span>Retry</span>
        </button>
      </section>

      <div v-else-if="!loading && !loading_failed">

        <div class="tabs">
          <ul>
            <li :class="{'is-active': active_tab === 'active_memberships'}">
              <a href="#" @click.prevent="active_tab='active_memberships'" :style="{paddingTop: '0.75em', paddingBottom: '0.75em'}">
                Active <span class="tag is-light ml-2">{{data.filter(i => i.is_accepted).length}}</span>
              </a>
            </li>
            <li :class="{'is-active': active_tab === 'pending_memberships'}">
              <a href="#" @click.prevent="active_tab='pending_memberships'" :style="{paddingTop: '0.75em', paddingBottom: '0.75em'}">
                Pending acceptance <span class="tag is-light ml-2">{{data.filter(i => !i.is_accepted).length}}</span>
              </a>
            </li>
          </ul>
        </div>

        <table
          v-if="active_tab === 'active_memberships'"
          class="table is-fullwidth is-hoverable is-striped"
        >
          <tbody>
            <tr v-for="item in data.filter(i => i.is_accepted)" :key="item.id">
              <td :style="{width: '100%'}">{{item.owner}}</td>
              <td>
                <strong v-if="item.is_default">Being used as default</strong>
                <span v-else>Not being used</span>
              </td>
              <td>
                <button
                  :class="['delete-button button is-white is-small', {'is-loading': deleting}]"
                  @click.prevent="deleteMembership(item.id)"
                >
                  <span :class="['icon is-small', {'has-text-danger': !deleting}]">
                    <font-awesome-icon :icon="['fas', 'times']" />
                  </span>
                </button>
              </td>

            </tr>
          </tbody>
        </table>

        <table
          v-if="active_tab === 'pending_memberships'"
          class="table is-fullwidth is-hoverable is-striped"
        >
          <tbody>
            <tr v-for="item in data.filter(i => !i.is_accepted)" :key="item.id">
              <td :style="{width: '100%'}">{{item.owner}}</td>
              <td>
                <button
                  :class="['delete-button button is-white is-small', {'is-loading': deleting}]"
                  @click.prevent="deleteMembership(item.id)"
                >
                  <span :class="['icon is-small', {'has-text-danger': !deleting}]">
                    <font-awesome-icon :icon="['fas', 'times']" />
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <bulma-modal
      v-if="is_invite_members_modal_active"
      :is_active="true"
      @close="is_invite_members_modal_active = false"
    >
      <invite-members-form
        :account_id="account.id"
        @close="is_invite_members_modal_active = false"
        @submitted="is_invite_members_modal_active = false; loadData()"
      />
    </bulma-modal>

  </div>

</template>

<script>
import api_billing from './../../apis/user_api/billing';
import { get_sso_url } from './../../utils/sso';

import BulmaModal from './../../components/common/BulmaModal'
import LoadingIndicator from './../../components/common/LoadingIndicator'
import InviteMembersForm from './forms/InviteMembersForm'

export default {
  name: 'account-members',
  components: {
    BulmaModal,
    LoadingIndicator,
    InviteMembersForm
  },
  props: {
    account: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loading_failed: false,
      deleting: false,
      deleting_failed: false,
      is_invite_members_modal_active: false,
      active_tab: 'active_memberships',
      data: [],
    }
  },
  methods: {
    deleteMembership(membership_id) {
      return this.$confirm(
        'Are you sure?',
        null,
        'warning',
        {
          confirmButtonText: 'Yes, delete now'
        }
      )
      .then(
        () => {
          this.deleting = true
          this.deleting_failed = false
          return api_billing.delete_membership_as_owner(membership_id)
            .then(response => {
              if (response.status == 204) {
                this.deleting = false
                this.loadData()
              } else {
                return Promise.reject({response})
              }
            })
            .catch(error => {
              this.deleting = false
              this.deleting_failed = true
              if (error.response && error.response.status == 401) {
                // Redirect to single sign-on url
                window.location.assign(get_sso_url())
              } else {
                alert('Something went wrong.')
                // re-raise
                return Promise.reject(error)
              }
            })
        },
        () => {
          // do nothing on cancel
          return Promise.resolve()
        }
      )
    },
    loadData() {
      this.loading = true;
      this.loading_failed = false;
      return api_billing
        .get_memberships_list_as_owner(this.account.id)
        .then((response) => {
          if (response.status == 200) {
            this.data = response.data;
            this.loading = false;
            return Promise.resolve(response.data);
          } else {
            return Promise.reject({ response });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.loading_failed = true;
          return Promise.reject(error);
        });
    },
  },
  mounted: function () {
    this.loadData();
  }
};
</script>
