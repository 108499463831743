<template>
  <div id="app">
    <router-view></router-view>
    <div hidden id="snipcart" :data-api-key="SNIPCART_PUBLIC_API_KEY"></div>
  </div>
</template>

<script>
import config from './../app.config.json'


export default {
  name: 'app',
  computed: {
    SNIPCART_PUBLIC_API_KEY: function () {
      return config.SNIPCART_PUBLIC_API_KEY
    }
  },
}
</script>
