<template>
  <div :class="['modal', {'is-active': is_active}]">

    <div
      class="modal-background"
      @click="$emit('close')"
    ></div>

    <div class="modal-content">
      <slot></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'bulma-modal',
  props: {
    is_active: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close'],
}
</script>
