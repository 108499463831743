import api_user from './../apis/user_api/user.js'
import { get_sso_url } from './sso.js'


export const checkAuthBeforeRouteEnter = (to, from, next) => {
  return api_user.get_me()
    .then(response => {
      if (response.status == 200) {
        next(vm => {
          vm.me = response.data
        })
      } else {
        return Promise.reject({response})
      }
    })
    .catch(error => {
      if (error.response && error.response.status == 401) {
        // Redirect to single sign-on url
        window.location.assign(get_sso_url())
      } else {
        // re-raise
        return Promise.reject(error)
      }
    })
}
