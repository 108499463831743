<template>
  <div>


    <header class="modal-card-head">
      <p class="modal-card-title">Add a member</p>
      <button
        class="button is-white"
        :disabled="submitting"
        @click.prevent="$emit('close')"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'times']" />
        </span>
      </button>
    </header>
    <section class="modal-card-body">

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label has-text-left">Email address</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                v-model="v$.form.owner.$model"
                :class="['input', {'is-danger': v$.form.owner.$error}]"
                type="text"
              />
              <div v-if="v$.form.owner.$error">
                <p class="help is-danger" v-if="!v$.form.owner.required">Field is required</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

    <footer class="modal-card-foot">
      <div class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <button
              :class="['forward-button button is-pozetron-primary has-tooltip-left', {'is-loading': submitting}]"
              :disabled="v$.$invalid"
              :data-tooltip="v$.$invalid?'Please ensure all fields are valid':null"
              @click.prevent="handleSubmit"
            >
              Invite
            </button>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
          <div class="level-item">
            <button
              class="back-button button is-light"
              :disabled="submitting"
              @click.prevent="$emit('close')"
            >Cancel</button>
          </div>
        </div>
      </div>

    </footer>

  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import api_billing from './../../../apis/user_api/billing.js'
import { get_sso_url } from './../../../utils/sso.js'

export default {
  name: 'invite-members-form',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  emits: ['close', 'submitted'],
  props: {
    account_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      form: {
        account: this.account_id,
        owner: '',
      }
    }
  },
  validations: {
    form: {
      account: {
        required
      },
      owner: {
        required
      },
    }
  },
  methods: {
    handleSubmit() {
      this.submitting = true

      return api_billing.create_membership_as_owner(this.form)
        .then(response => {
          if (response.status == 201) {
            this.submitting = false
            this.$emit('submitted', response.data)
            return Promise.resolve()
          } else {
            return Promise.reject(response)
          }
        })
        .catch(error => {
          this.submitting = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else if (error.response && error.response.status == 422) {
            alert('Data failed server-side validation.')
            // re-raise, so developers can be alerted of caught edge-case
            return Promise.reject(error)
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
  }
}
</script>
