import config from './../../app.config.json'


export const get_sso_url = () => {
  // SSO Handle is assumed to be the first part of the hostname the mobile app is running on
  // SSO Handle of "blah.apps.pozetron.com" is "blah"
  // SSO Handle of "localhost:8080" is "localhost"
  const SSO_HANDLE = window.location.host.split(':')[0].split('.')[0]

  // Return full sso url
  return `${config.SSO_URL}${SSO_HANDLE}/`
}
