<template>
  <div>

    <b-navbar class="is-pozetron-primary" wrapper-class="container is-fluid">

        <template #brand>
            <b-navbar-item tag="span" :style="{'margin-right': '2rem'}">
              <strong>Pozetron</strong>
            </b-navbar-item>
            <b-navbar-item tag="span" :style="{'gap': '.75rem'}">
              <span>Devices</span>
              <b-button class="button is-pozetron-primary" size="is-small" :active="is_sidebar_active" @click.prevent="is_sidebar_active=!is_sidebar_active">
                <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
              </b-button>
            </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-dropdown arrowless right>
                <template #label>
                  <span class="button is-light is-small is-hidden-mobile is-hidden-tablet-only">
                    <span>New</span>
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fas', 'caret-down']" />
                    </span>
                  </span>
                  <span class="is-hidden-desktop">
                    New
                  </span>
                </template>
                <b-navbar-item
                  v-bind="{
                    ...devices_app_base_url === ''
                      ?{
                        tag: 'router-link',
                        to: '/claim'
                      }
                      :{
                        href: `${devices_app_base_url}/claim`
                      }
                  }"
                >
                    <div :style="{'display': 'flex', 'flex-direction': 'column', 'align-items': 'flex-start'}">
                      <span>Device</span>
                      <span class="is-size-7 has-text-grey">Claim a device</span>
                    </div>
                </b-navbar-item>
                <b-navbar-item
                  v-bind="{
                    ...devices_app_base_url === ''
                      ?{
                        tag: 'router-link',
                        to: '/modules/new'
                      }
                      :{
                        href: `${devices_app_base_url}/modules/new`
                      }
                  }"
                >
                    <div :style="{'display': 'flex', 'flex-direction': 'column', 'align-items': 'flex-start'}">
                      <span>Module</span>
                      <span class="is-size-7 has-text-grey">Deploy a module</span>
                    </div>
                </b-navbar-item>
                <b-navbar-item
                  v-bind="{
                    ...actions_app_base_url === ''
                      ?{
                        tag: 'router-link',
                        to: '/new'
                      }
                      :{
                        href: `${actions_app_base_url}/new`
                      }
                  }"
                >
                    <div :style="{'display': 'flex', 'flex-direction': 'column', 'align-items': 'flex-start'}">
                      <span>Action</span>
                      <span class="is-size-7 has-text-grey">Deploy an action</span>
                    </div>
                </b-navbar-item>
                <!-- <b-navbar-item tag="span" :style="{'display': 'flex', 'align-items': 'center', 'gap': '1rem', 'padding-right': '1.5rem'}">
                    <div :style="{'display': 'flex', 'flex-direction': 'column', 'align-items': 'flex-start'}">
                      <span>Dashboard</span>
                      <span class="is-size-7 has-text-grey">Create a dashboard</span>
                    </div>
                    <span class="tag is-pozetron-primary is-small">Soon</span>
                </b-navbar-item> -->
            </b-navbar-dropdown>
        </template>
    </b-navbar>



      <section class="sidebar-layout">

        <b-sidebar
          type="is-white"
          position="static"
          fullheight
          v-model="is_sidebar_active"
        >
          <b-menu
            icon-pack="fas"
          >
            <b-menu-list label="Manage">
              <b-menu-item
                v-bind="{
                  label: 'Devices',
                  activeClass: 'is-active',
                  ...devices_app_base_url === ''
                    ?{
                      tag: 'router-link',
                      to: '/devices'
                    }
                    :{
                      href: `${devices_app_base_url}/devices`
                    }
                }"
              ></b-menu-item>
              <b-menu-item
                v-bind="{
                  label: 'Modules',
                  activeClass: 'is-active',
                  ...devices_app_base_url === ''
                    ?{
                      tag: 'router-link',
                      to: '/modules'
                    }
                    :{
                      href: `${devices_app_base_url}/modules`
                    }
                }"
              ></b-menu-item>
              <b-menu-item
                v-bind="{
                  label: 'Actions',
                  activeClass: 'is-active',
                  ...actions_app_base_url === ''
                    ?{
                      tag: 'router-link',
                      to: '/'
                    }
                    :{
                      href: `${actions_app_base_url}`
                    }
                }"
              ></b-menu-item>
              <b-menu-item
                v-bind="{
                  label: 'Billing',
                  activeClass: 'is-active',
                  ...billing_app_base_url === ''
                    ?{
                      tag: 'router-link',
                      to: '/'
                    }
                    :{
                      href: `${billing_app_base_url}`
                    }
                }"
              ></b-menu-item>
              <!-- <b-menu-item label="Dashboards" :href="`${dashboards_base_url}/#source=default`"></b-menu-item> -->
            </b-menu-list>
            <b-menu-list label="Explore">
              <b-menu-item label="Shop" :href="`${shop_base_url}/products`" target="_blank"></b-menu-item>
              <b-menu-item label="Tutorials" :href="tutorials_base_url" target="_blank"></b-menu-item>
            </b-menu-list>
          </b-menu>
        </b-sidebar>

        <router-view class="page-content"></router-view>
      </section>
  </div>
</template>

<script>
// TODO: once the component is compatible with Vue3, re-extract it component into the shared pozetron/pozetron-web-shared-layout repo
// after migrating it out of the deprecated rollup approach for bundling the component as a shareable library
// https://github.com/team-innovation/vue-sfc-rollup?tab=readme-ov-file#deprecated

export default {
  name: 'pozetron-web-shared-layout',
  props: {
    devices_app_base_url: {
      type: String,
      default: 'https://devices.apps.pozetron.com'
    },
    actions_app_base_url: {
      type: String,
      default: 'https://actions.apps.pozetron.com'
    },
    dashboards_base_url: {
      type: String,
      default: 'https://dashboards.pozetron.com'
    },
    shop_base_url: {
      type: String,
      default: 'https://shop.pozetron.com'
    },
    tutorials_base_url: {
      type: String,
      default: 'https://www.pozetron.com/tutorials'
    },
    billing_app_base_url: {
        type: String,
        default: 'https://billing.apps.pozetron.com'
    }
  },
  data() {
    let stored_is_sidebar_active
    try {
      stored_is_sidebar_active = JSON.parse(localStorage.getItem('is_sidebar_active'))
    } catch (error) {
      stored_is_sidebar_active = true
    }
    return {
      is_sidebar_active: stored_is_sidebar_active
    }
  },
  watch: {
    is_sidebar_active: function(value) {
      localStorage.setItem('is_sidebar_active', JSON.stringify(value))
    }
  }
}
</script>

<style lang="scss">

/* Ensure strong text is white in navbar */
.navbar.is-pozetron-primary .navbar-item strong {
  color: #fff;
}

.navbar > .container > .navbar-menu:first-child {
  flex-grow: initial;
  margin-left: -0.75rem;
  margin-right: initial;
}

.navbar > .container .navbar-menu + .navbar-brand {
  margin-left: initial;
}

.navbar-burger:first-child {
    margin-left: initial !important;
}

.sidebar-content {
  padding: 1rem;
}


.sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    min-height: 100vh;
}
.page-content {
  flex-grow: 1;
}

</style>
