<template>
  <router-link to="/personal" :style="{display: 'block'}">
    <div :class="[{ 'default-billing-account': data.is_default }, 'card']">
      <div class="card-content">
        <div class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <strong>
                {{data.name?data.name:'Anonymous'}}
              </strong> <default-button
                class="ml-4 has-tooltip-right"
                data-tooltip="By making this your default account, any paid usages in Pozetron services will be registered to it."
                type="personal"
                :id="data.id"
                :is_default="data.is_default"
                @made_default="$emit('made_default')"
              />
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right">

            <div class="level-item">
              <div class="tags has-addons">
                <span class="tag is-pozetron-primary">Balance</span>
                <span class="tag"><strong>{{centimes_to_credits(data.balance)}} Credits</strong></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { centimes_to_credits } from './../../../utils/credits'

import DefaultButton from './../DefaultButton'


export default {
  name: 'personal-billing-account-list-item',
  components: {
    DefaultButton
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['made_default'],
  methods: {
    centimes_to_credits
  }
}
</script>

<style scoped>
.is-disabled{
  pointer-events: none;
  opacity: .65;
}
.default-billing-account {
  border: 2px solid #fc6e51;
}
</style>
